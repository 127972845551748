@import url("colors.css");

* {
	box-sizing: border-box;
}

html,
body,
div {
	/* font-family: "Roboto Light", "Helvetica", "Arial", sans-serif;
	font-size: 12pt;
	font-weight: 100 !important;
	color: var(--text) !important; */
	margin: 0;
	padding: 0;
}

html,
body,
#root,
.body {
	width: 100%;
	height: 100% !important;
}

html,
body,
#root {
	overflow: hidden;
}

.body {
	overflow-y: auto;
	overflow-x: hidden;
}


a {
	color: inherit;
	text-decoration: inherit;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0 !important;
}


.page {
	padding-top: 57px;
	width: 100%;
	height: 100%;
	background-color: var(--white);
	overflow: hidden;
}

.scrolling::-webkit-scrollbar {
	width: 0 !important
}


.footer {
	height: var(--footer-height);
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
	align-items: center;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--white);
	max-height: var(--footer-height);
	border-top: solid 1px #CCC;
	/* padding: 12px; */
}


.button {
	background-image: linear-gradient(136deg, #3023AE 0%, #C86DD7 100%);
	border-radius: 5px;
	font-size: 16px;
	color: #FFFFFF !important;
	letter-spacing: 0;
	text-align: center;
	line-height: 48px;
	display: table;
	margin: 0 auto;
	padding: 0px 16px;
}




/* OVERRIDES */

.MuiTableCell-root {
	font-family: "Roboto Light", "Helvetica", "Arial", sans-serif !important;
	border-bottom-style: dashed !important;
}

.MuiTableCell-head {
	border-bottom: solid 1px #A8A8A8 !important;
}


.MuiTableCell-body {
	color: var(--text) !important;
	font-weight: 200 !important;
	letter-spacing: 1.17pt !important;
}

.MuiTableCell-head {
	color: var(--text) !important;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
	font-weight: 800 !important;
	font-size: 11pt !important;
	letter-spacing: 1.17pt !important;
}


/* OVERRIDE NOTIFICATION STYLES */
.notification {
	padding: 15px 15px 15px 15px !important;
}

.notification:before {
	display: none !important;
}

.notification-warning {
	background-color: var(--purple) !important;
}

.indicators {
	height: 48px;
}

.indicators li {
    width: 48px;
    height: 48px;
    margin: 10px;
    text-indent: 0;
    cursor: pointer;
    border: none;
    border-radius: 50%;
	background-color: #E0E0E0 !important;
	will-change: auto;
}
.indicators .active {
    width: 48px;
    height: 48px;
    margin: 10px;
    background-color: var(--purple) !important;
}

.slider-slide {
	width: 100% !important;
  }
  
.slider-list {
	left: 0 !important;
}

.tsys-input {
    margin: 6px;
    width: 100%;
}

#tsep-datepicker{
    width: 180px !important;
    float: left;
    margin-right: 2px;
}

#tsep-cvv2{
    width: 113px !important;
    float: right;
    margin-left: 0px;
    margin-right: 0px;
}

#tsep-cardNumDiv{
    margin-top:0px;
}
