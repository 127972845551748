:root {
	--black: #000000;
	--white: #ffffff;
	--purple: #9160DC;
	--purple-text: #9160DC;
	--divider: #DDDDDD;
	--text: #373737;
	--loyal-text: #6f6f6f;
	--dark-gray: #4d4d4d;
	--light-gray: #858585;
	--gray-background: rgb(250,250,250);
	--header-height: 62px;
	--footer-height: 62px;
	--mask: #AAAAAA99;

	--vehicle-white: #FFFFFF;
	--vehicle-black: #000000;
	--vehicle-silver: #E4E4E4;
	--vehicle-gray: #777777;
	--vehicle-red: #CE0016;
	--vehicle-teal: #0091A6;
	--vehicle-blue: #001783;
	--vehicle-green: #006831;
	--vehicle-brown: #987700;
	--vehicle-other: var(--purple);

}