body {
    font-family: sans-serif;
}

#iframes {
    width: 300px;
    margin: auto;
    height: 100%;
}

label[for=iframesCardNumber], label[for=iframesCardExpiration], label[for=iframesCardCvv] {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    color: #555;
    line-height: 1.5;
}

#ss-banner {
    background: transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-shield@2x.png?raw=true) no-repeat left center;
    height: 40px;
    background-size: 280px 34px;
    margin-bottom: 7px;
}

user agent stylesheet
.form-row {
  margin-top: 10px;
  width: 300px;
  height:44px;
}

.form-row {
    height: 44px
}

input {
    background: #fff;
    border: 1px solid;
    border-color: #bbb3b9 #c7c1c6 #c7c1c6;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 14px;
    line-height:1.42;
    margin: 0 .5em 0 0;
    max-width: 100%;
    outline: 0;
    padding: 6px 12px;
    vertical-align: baseline;
    height : 44px;
    width : 100% !important;
  }

  .form-wrapper {
      padding: 16px;
  }