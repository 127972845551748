.enter {
    bottom: 0px;
    transition: transform 1s linear;
}

.exit {
    bottom: -240px;
    transition: transform 1s linear;
}

#id_recaptcha .ng-binding {
    text-indent: -9999px;
    line-height: 0; /* Collapse the original line */
}

#id_recaptcha .ng-binding::after {
    content: "Continue";
    text-indent: 0;
    display: block;
    line-height: initial; /* New content takes up original line height */
}